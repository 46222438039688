import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../appSlice';
import alertDialogReducer from '../features/alert/alertDialogSlice';
import signInReducer from '../signInSlice';
import productListReducer from '../features/product/list/productListSlice';
import productItemReducer from '../features/product/item/productItemSlice';
import categorySlice from '../features/category/categorySlice';
import highlightSlice from '../features/highlight/highlightSlice';
import versionSlice from '../features/product/item/versions/versionSlice';
import featureSlice from '../features/product/item/features/featureSlice';
import userSlice from '../features/user/userSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    alertDialog: alertDialogReducer,
    signIn: signInReducer,
    products: productListReducer,
    product: productItemReducer,
    categories: categorySlice,
    highlights: highlightSlice,
    versions: versionSlice,
    features: featureSlice,
    users: userSlice
  },
});
