import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { signInSuccess } from './appSlice';

export const signInSlice = createSlice({
  name: 'signIn',
  initialState: {
    loading: false,
    error: ''
  },
  reducers: {
    signInPending: state => {
      state.loading = true;
    },
    signInFailure: (state, error) => {
      state.loading = false;
      state.error = error.payload;
    },
  },
});

export const { signInPending, signInFailure } = signInSlice.actions;

export const signInUser = (email, password) => dispatch => {
  dispatch(signInPending());
  axios.post(process.env.REACT_APP_API_ENTRYPOINT + '/authentication_token', {
    email: email,
    password: password
  })
    .then(response => dispatch(signInSuccess(response.data)))
    .catch(error => dispatch(signInFailure(error.message)))
};

export default signInSlice.reducer;
