import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  title: 'Modal title',
  content: null,
  buttons: []
};

export const alertDialogSlice = createSlice({
  name: 'alertDialog',
  initialState: initialState,
  reducers: {
    openModal: (state, action) => {
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.buttons = action.payload.buttons;
      state.open = true;
    },
    closeModal: state => {
      state.open = false;
    },
    addButton: (state, action) => {
      state.buttons.push(action.payload);
    },
    clearButtons: state => {
      state.buttons = initialState.buttons;
    }
  },
});

export const { openModal, closeModal, addButton, clearButtons } = alertDialogSlice.actions;

export default alertDialogSlice.reducer;
